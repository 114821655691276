footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--color-bg);
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 2rem;
}

.footer__copyright {
  margin-bottom: 5rem;
}
/* Media Queries (Med devices)*/
@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }
}
